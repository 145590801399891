import React from 'react';
import { BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import { Button, Layout } from 'antd'; 

import Login  from './pages/Login';
import AppLayout from './pages/AppLayout';
import Dashboard from './pages/Dashboard';
import Menus from './pages/Menus';
import { theme } from 'antd';
import Submenus from './pages/Submenus';
import Elements from './pages/Elements';
import ElementMapping from './pages/ElementMapping';
import Users from './pages/Users';
import Permissions from './pages/Permissions';
import Courses from './pages/Courses';
import Modules from './pages/Modules';
import Studentdetails from './pages/Studentdetails';
import Studentprofile from './pages/Studentprofile';
import Personalinfo from './pages/ProfileComp/Personalinfo';
import Registration from './pages/Registration';
import Viewattendance from './pages/Viewattendance';
import Documents from './pages/Documents';
import Installments from './pages/Installments';
import Staffdetails from './pages/Staffdetails';
import Exams from './pages/Exams';
import Marks from './pages/Marks';

import Certificates from './pages/Certificates';
import Editstudent from './pages/Editstudent';
import Takeattendance from './pages/Takeattendance';
import Centres from './pages/Centres';
import Crflist from './pages/StdstatusComp/Crflist';
import Paymentreports from './pages/Paymentreports';
import Assignment from './pages/Assignment';
function App() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (

    <HashRouter >
     <div className="App">
      <Routes>
        <Route path='login'  element={<Login />} />
        <Route path='attendance' element={<Takeattendance />} />
        <Route path='/' element={<AppLayout />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='menus' element={<Menus />} />
          <Route path='submenu' element={<Submenus />} />
          <Route path='elements' element={<Elements />} />
          <Route path='elementsmap' element={<ElementMapping />} />
          <Route path='users' element={<Users />} />
          <Route path='permissions/:id' element={<Permissions />} />
          <Route path='courses' element={<Courses />} />
          <Route path='modules' element={<Modules />} />
          <Route path='studentlist' element={<Studentdetails />} />
          <Route path='studentprofile/:id/:name/:img/:cname' element={<Studentprofile />} />
          <Route path='registration' element={<Registration />} />
          <Route path='viewattendance' element={<Viewattendance />} />
          <Route path='documents' element={<Documents />} />
          <Route path='installments/:id' element={<Installments />} />
          <Route path='stafflist/' element={<Staffdetails />} />
          <Route path='exams' element={<Exams />} />
          <Route path='marks/:id' element={<Marks />} />
          <Route path='certificates/:id' element={<Certificates />} />
          <Route path='editstudentdetails/:id' element={<Editstudent />} />
          <Route path='centres' element={<Centres />} />
          <Route path='crflist' element={<Crflist />} />
          <Route path='paymentreports' element={<Paymentreports />} />
          <Route path='assignments' element={<Assignment />} />
          
        </Route>
      </Routes>
        
    </div>
    </HashRouter>
    
  );
}

export default App;  
