import axios from "axios";
import { toast } from 'react-toastify';




/*let uri = 'http://127.0.0.1:8000/';
export const img_url = 'http://127.0.0.1:8000/uploads/';
export const assign = 'http://127.0.0.1:8000/assigndocs/';
export const stf_url = 'http://127.0.0.1:8000/staff_img/';
export const back_url = 'http://127.0.0.1:8000/';*/

let uri = 'https://avksladmin.aranyakvalley.com/backend/';
export const img_url = 'https://avksladmin.aranyakvalley.com/backend/public/uploads/';
export const assign = 'https://avksladmin.aranyakvalley.com/backend/public/assigndocs/';
export const stf_url = 'https://avksladmin.aranyakvalley.com/backend/public/staff_img/';
export const back_url = 'https://avksladmin.aranyakvalley.com/backend/';

let config = {
        headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
}


export const getData = async (url)=>{
    return await axios.get(uri+url);
}

export const postData = async (url,data)=>{
    console.log(uri+url);
    return await axios.post(uri+url,data);
}
 
export const AuthUser = async (url,data)=>{
    return await axios.post(uri+url,data);
}

export const getPagePer = async (url)=>{
    return await axios.get(uri+url);
}

export const CheckLogin = ()=>{
    let login = localStorage.getItem('login');
    if(login!=null){
        return 1;
    }else{
        return 0;
    }
}


export const successMsg = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }); 
export const errorMsg = (msg) => toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });

