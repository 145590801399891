import React, { useCallback, useEffect, useState } from 'react'
import { Outlet,useNavigate,useLocation } from 'react-router-dom'
import {errorMsg, getData, getPagePer, successMsg} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Spin, Table, Tag, Upload, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
//import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';
import moment from 'moment';
import { assign } from '../services/index';

let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let std_id:any;
let user_id:any;

const { Option } = Select;
let options: any=[];

type FieldType = {
    centre?:string;
    assign_topic?:string;
    module_id?:string;
    stdlist?:string;
  
  };

interface DataType {
    assign_id:number,
    std_id : string;
    std_name?: string;
    module_name?: string;
    assign_topic?: string;
    assign_file?: string;
   
  }



  let  image:any;
function Assignment() {

     const [centreData, setCentreData] = useState([]);
     const [AssignData, setData] = useState([]);
     const [showAddBtn, setAddBtn] = useState(false);
     const [showEditBtn, setEditBtn] = useState(false);
     const [data, setElement] = useState();
     const [loading, setLoading] = useState(true);
     const location = useLocation();
     const m_id = location.state.id;
     const type = location.state.type;
     const [isModalOpen, setIsModalOpen] = useState(false);
     const [form] = Form.useForm();
     const [stdData, setStdData] = useState([]);
     const [module_id, setModule] = useState([]);
     const [FileData, setFileData] = useState();

     const handleCancel = () => {
        setIsModalOpen(false);
      //  setIsModalOpenEdit(false);
      };
      const handleOk = () => {
    };

     const columns: ColumnsType<DataType> = [
         {
           title: 'Student ID',
           dataIndex: 'std_id',
           key: 'std_id',
           
         },
         {
           title: 'Name',
           dataIndex: 'std_name',
           key: 'std_name',
         },
         {
             title: 'Module',
             dataIndex: 'module_name',
             key: 'module_name',
         },
         {
           title: 'Topic',
           dataIndex: 'assign_topic',
           key: 'assign_topic',
         },
         {
             title: 'File',
             dataIndex: 'assign_file',
             key: 'assign_file',
             render: (_, {assign_file}) => (
                <>
                    <a href={assign + assign_file} download={true}>Download</a>
                  
                </>
            ),
         },
         
         {
           title: 'Action',
           key: 'action',
           render: (_, {assign_id}) => (
               <><Button type="primary" danger size='small'><i className="fas fa-ban"></i></Button>
               
                 
               </>
           ),
           
         },
       ];

       const showModal = () => {
        setIsModalOpen(true);
      };

    const loadCentre = ()=>{
        getData('getcentres/').then((res)=>{
          setCentreData(res.data)
        });
      }
    const loadCourseData = ()=>{
    
        //getData('getcourses/').then((res)=>setCourse(res.data));
      
        //console.log(svrData); 
         
      }
    
      const loadAssignData = ()=>{
        getData('getassign/').then((res)=>setData(res.data));
        setLoading(false);
        //console.log(AssignData);
      }

      function onFinishFailed(): void {
        throw new Error('Function not implemented.');
      }
    
      function onFinish(values: any): void {
        throw new Error('Function not implemented.');
      }

      const handleCentreChange = (value: string) => {
        centre_code = value;
        std_id = 0;
        loadStdList();
       // loadData();
      }

      const loadStdList = ()=>{
        // console.log(centre_code);
        getData('getStdlist/'+String(centre_code)).then((res)=>{
            options = res.data;
            setStdData(res.data);
            
            // console.log(stdData);
        })
        }
        const handleChange = (value: string) => {
          std_id = value;
            getData('getmoduleliststd/'+String(std_id)).then((res)=>{
             // options = res.data;
             setModule(res.data);
              
              // console.log(stdData);
          })
        }
        const handleImage = (e:any) =>{
          //console.log(e.target.files[0])
          //setFileData(e.target.files[0]);
         // console.log(FileData);
          image = e.target.files[0];
        }

      const onFormSubmit = useCallback(async () => {
          form
            .validateFields()
            .then(() => {
              // Validation is successful
              let value = form.getFieldsValue();
              let formData = new FormData();
              //formData.append("centre_code",value.centre_code);
              formData.append("centre",value.centre);
              formData.append("stdlist",value.stdlist);
              formData.append("module",value.module_id);
              formData.append("assign",value.assign_topic);
              formData.append("file",image);
             
            
              postData('addassgin',formData).then((res)=>{
              //console.log(res);
              console.log(res.data)
              if(res.data){
                //setMenuName("");
      
                if(res.data == 1){
                  successMsg("Assignment Added");
                  setIsModalOpen(false);
                 // loadData();  
                }else{
                  errorMsg("Error Try Again");
                  setIsModalOpen(false);
                  //loadData();
                }

                loadAssignData();
                
              }
            })
      
            })
            .catch((errors) => { 
              // Errors in the fields
      
              console.log("no");
            });
        }, [form]);

    useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        user_id = login.user_id;

        getPagePer('getuserper/'+m_id+'/'+type+'/'+user_id).then((res:any)=>{
                  for(let i=0;i<res.data.length;i++){
                    switch(parseInt(res.data[i].per_element_id)){
                      case 2:
                            setAddBtn(true);
                            break;
                      case 3:
                            setEditBtn(true);
                            break;
        
                    }
                  }
                })
    
        
        loadCentre();
        loadCourseData();
        loadAssignData();
       
        //loadCertData();
       // form.setFieldValue('std_pin','788001');
        //form.setFieldValue('std_city','SILCHAR');
        //form.setFieldValue('std_state','ASSAM');
        //form.setFieldValue('std_course',10)
       },[]);

       return(
        <div className='Assaignment'>
            <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Assignments</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
            <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Assignment List" extra={<Button className={showAddBtn? '' : 'hidden'} type='primary'  onClick={showModal} >Add New Assignment</Button>} >
                    <Spin tip="Loading" size="large" spinning={loading}>
                        <Table className='font-style' columns={columns} dataSource={AssignData} size="small" scroll={{ x: 1200 }} />
                    </Spin>
                    </Card>
                    </div>
                </div>
            </div>
            </section>

            <Form
                          form={form}
                          name="basic"
                          labelCol={{ span: 24 }}
                          layout="vertical"
                          initialValues={{ remember: true }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          
                        >
            
                    <Modal title="Add Assignment" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
                      <Button key="back" className='font-style' onClick={handleCancel}>
                        Cancel
                      </Button>,
                      <Button  type="primary" className='font-style' onClick={onFormSubmit}  > 
                        Submit
                      </Button>,
                    ]}>

                      <Form.Item<FieldType> 
                            label="Select Centre"
                            name="centre"
                            rules={[{ required: true, message: 'Please Select Centre!' }]}>
                            <Select placeholder="Select Centre" onChange={handleCentreChange}>
                                {
                                  adm_type == 1 ? (
                                    centreData.map((item:any)=>{
                                      return(
                                        <Select.Option value={item.centre_code}>{item.centre_name}</Select.Option>
                                      )
                                    })
                                  ) : (
                                    <Select.Option value={centre_code}>{centre_name}</Select.Option>
                                  )
                                }
                              
                            </Select>
                          </Form.Item>
                          <Form.Item<FieldType> 
                              label="Select Student"             
                              name="stdlist"
                              rules={[{ required: true, message: 'Please Select Centre!' }]}
                              >
                            <Select placeholder="Select Name"    className='flex-fill' 
                              
                              showSearch
                              onChange={handleChange}
                             
                              optionFilterProp="children"
                              filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                              filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                              }
                              options={options}
      
                            >
                            
                            </Select>
                            </Form.Item>

                            <Form.Item
                                name="module_id"
                                label="Choose Module"
                                rules={[{ required: true, message: 'Please select Module' }]}
                              >
                                <Select placeholder="Please select Module">
                                  {
                                    module_id.map((item:any)=>{
                                      return(
                                        <Select.Option value={item.module_id}>{item.module_name}</Select.Option>
                                      )
                                    })
                                  }
                                </Select>
                              </Form.Item>
                       
            
                          <Form.Item<FieldType>
                            label="Topic"
                            name="assign_topic"
                            rules={[{ required: true, message: 'Please enter Assignment Topic !' }]}
                          >
                           <Input />
                          </Form.Item>

                          <Form.Item label="Choose Image" name="fileList" rules={[{ required: true, message: 'Please Choose Image!' }]}>
                              <input type='file' onChange={handleImage} className='form-control'/>
                            </Form.Item>
            
                          
                        
            
                          
            
                    </Modal> 
                    </Form>

          </div>
        
       )

}

export default Assignment;

