import React, { useCallback, useEffect, useState } from 'react'
import { Outlet,useLocation } from 'react-router-dom'
import {getData} from '../services/index';
import {postData} from '../services/index';
import { Link } from 'react-router-dom';
import { Button, Card, Form, Input, Modal, Select, Spin, Table, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';

import form from 'antd/es/form';
import {errorMsg, getPagePer,successMsg} from '../services/index';
import './style.css';

let centre_code:any;
let adm_type:any;
let centre_name:any;
let staff_code:any;
let std_id:any;
let user_id:any;

let options: any=[];
let course_id_edit:any;
let  select_cert:any=[];
type FieldType = {
  
  course_name?:string;
  course_fname?:string;
  course_duration?:number;
  course_days?:number;
  course_fee?:number;
  module_id?:string;

};
  
  interface DataType {
    course_id : number;
    course_name?: string;
    course_fname?: string;
    course_duration?: string;
    course_fee?: string;
    course_status?: string;
  }


 

function Courses() {

  const [data, setElement] = useState();
  const [ishide, ShowHide] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const m_id = location.state.id;
  const type = location.state.type;
  const [showAddBtn, setAddBtn] = useState(false);
  const [showEditBtn, setEditBtn] = useState(false); 

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const columns: ColumnsType<DataType> = [
    {
      title: 'Courses ID',
      dataIndex: 'course_id',
      key: 'course_id',
      
    },
    {
      title: 'Course Code',
      dataIndex: 'course_name',
      key: 'course_name',
    },
    {
        title: 'Course Name',
        dataIndex: 'course_fname',
        key: 'course_fname',
    },
    {
      title: 'Duration',
      dataIndex: 'course_duration',
      key: 'course_duration',
    },
    {
        title: 'Course Fee',
        dataIndex: 'course_fee',
        key: 'course_fee',
    },
    {
        title: 'Course Status',
        dataIndex: 'course_status',
        key: 'course_status',
        render: (course_status) => course_status == 1 ? <Tag color="success">Active</Tag> : <Tag color="error">In-Active</Tag>  

    },
    {
      title: 'Action',
      key: 'action',
      render: (_, {course_id}) => (
          <><Button type="primary" danger size='small'><i className="fas fa-ban"></i></Button>
          <Button className={showEditBtn?'' : 'hidden'}  type="primary" size='small' onClick={()=>showEditModel(course_id)}><i className='fas fa-edit'></i></Button>
            
          </>
      ),
      
    },
  ];
   



    const showModal = () => {
        setIsModalOpen(true);
      };
    const showEditModel = (id:any) => {
      setIsModalOpenEdit(true);
      course_id_edit = id;
      getData('getcourseupdate/'+id).then((res)=>{
        form1.setFieldValue('course_name',res.data['course_name']);
        form1.setFieldValue('course_fname',res.data['course_fname']);
        form1.setFieldValue('course_duration',res.data['course_duration']);
        form1.setFieldValue('course_days',res.data['course_days']);
        form1.setFieldValue('course_fee',res.data['course_fee']);
        form1.setFieldValue('course_days',res.data['course_days']);

        select_cert = res.data['module_id'].split(",").map(function(item: any){
          //return parseInt(item);
          return item;
        
        });

       // console.log(select_cert);

        form1.setFieldValue('module_id',select_cert);
      })
    }
      const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalOpenEdit(false);
      };
      const handleOk = () => {
      };

      const loadData = ()=>{

        getData('getcourses/').then((res)=>setElement(res.data));
      
        //console.log(svrData); 
         setLoading(false);
      }

      const getCourseModules = ()=>{

        getData('getmodulessel/').then((res)=>{
          options = res.data;
        });
       
        //console.log(svrData); 

         
      }
    
      useEffect(()=>{
        let login = JSON.parse(String(localStorage.getItem('login')));
        centre_code = login.centre_code;
        adm_type = login.adm_type;
        centre_name = login.centre_name;
        staff_code = login.staff_code
        user_id = login.user_id;
       
        getPagePer('getuserper/'+m_id+'/'+type+'/'+user_id).then((res)=>{
          for(let i=0;i<res.data.length;i++){
            switch(parseInt(res.data[i].per_element_id)){
              case 2:
                    setAddBtn(true);
                    break;
              case 3:
                    setEditBtn(true);
                    break;

            }
          }
        })
        loadData();
        getCourseModules();
       },[]);

  function onFinishFailed(): void {
    throw new Error('Function not implemented.');
  }

  function onFinish(values: any): void {
    throw new Error('Function not implemented.');
  }

  const onFormSubmit = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        // Validation is successful
        let value = form.getFieldsValue();
        let formData = new FormData();
        //formData.append("centre_code",value.centre_code);
        formData.append("course_name",value.course_name);
        formData.append("course_fname",value.course_fname);
        formData.append("course_duration",value.course_duration);
        formData.append("course_days",value.course_days);
        formData.append("course_fee",value.course_fee);
        formData.append("module_id",value.module_id);
      
        postData('addcourse',formData).then((res)=>{
        //console.log(res);
        console.log(res.data)
        if(res.data){
          //setMenuName("");

          if(res.data == 1){
            successMsg("Course Created");
            setIsModalOpen(false);
            loadData();  
          }else{
            errorMsg("Error Try Again");
            setIsModalOpen(false);
            loadData();
          }
          
        }
      })

      })
      .catch((errors) => { 
        // Errors in the fields

        console.log("no");
      });
  }, [form]);

  const onFormSubmitEdit = useCallback(async () => {
    form
      .validateFields()
      .then(() => {
        // Validation is successful
        let value = form1.getFieldsValue();
        let formData = new FormData();
        formData.append("course_id",course_id_edit);
        formData.append("course_name",value.course_name);
        formData.append("course_fname",value.course_fname);
        formData.append("course_duration",value.course_duration);
        formData.append("course_days",value.course_days);
        formData.append("course_fee",value.course_fee);
        formData.append("module_id",value.module_id);
      
        postData('updatecoursedata',formData).then((res)=>{
        //console.log(res);
        console.log(res.data)
        if(res.data){
          //setMenuName("");

          if(res.data == 1){
            successMsg("Course Updated");
            setIsModalOpenEdit(false);
            loadData();  
          }else{
            errorMsg("Error Try Again");
            setIsModalOpenEdit(false);
            loadData();
          }
          
        }
      })

      })
      .catch((errors) => { 
        // Errors in the fields

        console.log("no");
      });
  }, [form1]);

  return (
    <div className='Courses'>
        <div className="content-header mt-5">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Courses</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}

          </div>
          <section className="content">
              <div className="container-fluid">
                <div className="row p-2">
                    <div className="col-lg-12">
                    <Card className='font-style' title="Course List" extra={<Button className={showAddBtn? '' : 'hidden'} type='primary' >Add New Course</Button>} >
                    <Spin tip="Loading" size="large" spinning={loading}>
                        <Table className='font-style' columns={columns} dataSource={data} size="small" scroll={{ x: 1200 }} />
                    </Spin>
                    </Card>
                    </div>
                </div>
              </div>
            </section>

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              
            >

        <Modal title="Add New Course" className='font-style' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" className='font-style' onClick={onFormSubmit}  > 
            Submit
          </Button>,
        ]}>
           <Form.Item<FieldType> 
                label="Course Code"
                name="course_name"
                rules={[{ required: true, message: 'Please enter course code!' }]}>
                <Input />
              </Form.Item>

              <Form.Item<FieldType> 
                label="Course Name"
                name="course_fname"
                rules={[{ required: true, message: 'Please enter course name!' }]}>
                <Input />
              </Form.Item>
              
              <Form.Item<FieldType>
                label="Course Duration (in months)"
                name="course_duration"
                rules={[{ required: true, message: 'Please enter course duration !' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Course Days"
                name="course_days"
                rules={[{ required: true, message: 'Please enter course days role !' }]}
              >
               <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Course Fee"
                name="course_fee"
                rules={[{ required: true, message: 'Please enter course fee !' }]}
              >
               <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Course Modules"
                name="module_id"
                rules={[{ required: true, message: 'Please select altleast one module !' }]}
              >
              <Select placeholder="Please Select Students" mode="tags" showSearch
                    
                   
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toString().includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toString().toUpperCase().localeCompare((optionB?.label ?? '').toString().toUpperCase())
                    }
                    options={options}>
                
                 
                </Select>
              </Form.Item>

        </Modal>
        </Form>

        <Form
              form={form1}
              name="basic"
              labelCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              
            >

        <Modal title="Edit Course Details" className='font-style' open={isModalOpenEdit} onOk={handleOk} onCancel={handleCancel} footer={[
          <Button key="back" className='font-style' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button  type="primary" className='font-style' onClick={onFormSubmitEdit}  > 
            Update
          </Button>,
        ]}>
           <Form.Item<FieldType> 
                label="Course Code"
                name="course_name"
                rules={[{ required: true, message: 'Please enter course code!' }]}>
                <Input />
              </Form.Item>

              <Form.Item<FieldType> 
                label="Course Name"
                name="course_fname"
                rules={[{ required: true, message: 'Please enter course name!' }]}>
                <Input />
              </Form.Item>
              
              <Form.Item<FieldType>
                label="Course Duration (in months)"
                name="course_duration"
                rules={[{ required: true, message: 'Please enter course duration !' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Course Days"
                name="course_days"
                rules={[{ required: true, message: 'Please enter course days role !' }]}
              >
               <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Course Fee"
                name="course_fee"
                rules={[{ required: true, message: 'Please enter course fee !' }]}
              >
               <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Course Modules"
                name="module_id"
                rules={[{ required: true, message: 'Please select altleast one module !' }]}
              >
              <Select placeholder="Please Select Students" mode="tags" showSearch>

                          {
                                  options.map((item:any)=>{
                                    return(
                                      <Select.Option value={item.value}>{item.label}</Select.Option>
                                    )
                                  })
                                }
                
                 
                </Select>
              </Form.Item>

        </Modal>
        </Form>
    </div>
  )
}

export default Courses